// MARK: React
import React from "react";
import useStyles from "./style";

// MARK: Mobx

import { observer } from "mobx-react-lite";

// MARK: Tools
import FileUploader from "../../../stores/FileStore/FileUploader";

// MARK: Components
import {
	CircularProgress as MCircularProgress,
	IconButton as MIconButton,
	Card,
	CardContent,
	CardMedia,
	Typography,
} from "@material-ui/core";

// MARK: Material-Icons
import {
	Refresh as MRefreshIcon,
	Delete as MDeleteIcon,
	AttachFile as MFileIcon,
	Check as MCheck,
	Search as MSearch,
	HighlightOff as MError,
	GetApp as MGetApp,
} from "@material-ui/icons";

interface IProps {
	uploader: FileUploader;
	name?: string;
	removeItem: () => void;
	reloadItem: () => void;
	disableActions?: boolean;
	onlyRead?: boolean;
}

const CardUploaded: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const {
		uploader,
		reloadItem,
		name,
		removeItem,
		disableActions,
		onlyRead,
	} = props;

	const statusIcons = () => {
		switch (uploader.status) {
			case "uploading":
				return (
					<MCircularProgress
						value={uploader.progress}
						style={{ color: "#212121" }}
						size={12}
					/>
				);

			case "failed":
				return (
					<>
						<MIconButton
							className={classes.failedIcon}
							edge="end"
							disableRipple
						>
							<MError />
						</MIconButton>
						<MIconButton onClick={reloadItem} edge="end">
							<MRefreshIcon />
						</MIconButton>
					</>
				);

			case "success":
				return (
					<MIconButton
						edge="end"
						disableRipple
						className={classes.checkIcon}
						size="small"
					>
						<MCheck />
					</MIconButton>
				);
			// tslint:disable-next-line: no-duplicated-branches
			default:
				return (
					<MCircularProgress
						value={uploader.progress}
						style={{ color: "#212121" }}
						size={12}
					/>
				);
		}
	};
	const imageStatus = () => {
		switch (uploader.status) {
			case "uploading":
				return (
					<div className={classes.darkOverlay}>
						<MCircularProgress
							value={uploader.progress}
							style={{ color: "#212121" }}
						/>
					</div>
				);

			case "failed":
				return <div className={classes.redOverlay} />;

			case "success":
				return;

			default:
				return <div className={classes.darkOverlay} />;
		}
	};

	const FileType = () => {
		if (uploader.FileType === "file") {
			return (
				<div className={classes.cover}>
					<MFileIcon />
					<MIconButton
						target="_blank"
						href={uploader.getSrc()}
						className={classes.FloatIcon}
					>
						<MGetApp />
					</MIconButton>
					{imageStatus()}
				</div>
			);
		} else {
			return (
				<CardMedia className={classes.cover} image={uploader.getSrc()}>
					{imageStatus()}
					<MIconButton
						target="_blank"
						href={uploader.getSrc()}
						className={classes.FloatIcon}
					>
						<MSearch />
					</MIconButton>
				</CardMedia>
			);
		}
	};

	return (
		<Card className={onlyRead ? classes.onlyRead : classes.card}>
			<div className={classes.details}>
				<FileType />
				<CardContent className={classes.content}>
					<div className={classes.contentTexts}>
						<Typography variant="subtitle1" className={classes.texts}>
							{uploader.filePath ? uploader.filePath : null}
						</Typography>
						<Typography
							variant="subtitle2"
							color="textSecondary"
							className={classes.texts}
						>
							{name ? name : null}
						</Typography>
					</div>
					<div
						className={
							disableActions ? classes.disabledActions : classes.Iconscontent
						}
					>
						{statusIcons()}
						<MIconButton onClick={removeItem} size="small">
							<MDeleteIcon />
						</MIconButton>
					</div>
				</CardContent>
			</div>
		</Card>
	);
};

export default observer(CardUploaded);
