import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import Store from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { IconButton, TableCell, TableRow, Button } from "@material-ui/core";
import { Edit, Visibility, Delete, Add as AddIcon } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new Store(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`users/details/${id}`),
		[history],
	);

	const pushToEdit = useCallback(
		(id: string) => history.push(`users/edit/${id}`),
		[history],
	);

	const pushToCreate = useCallback(() => history.push("users/create"), [
		history,
	]);


	const handleDeleteUser = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.delete.message,
					title: Strings.dialogs.delete.title,
				},
				() => tableStore.deleteUser(id),
			);
		},
		[uiStore, tableStore],
	);


	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.user.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.fields.email,
					Strings.components.table.edit,
					Strings.components.table.details,
					Strings.components.table.delete,
				]}
				renderItem={(item) => (
					<TableRow hover key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.email}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => handleDeleteUser(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}

			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
