import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import DetailsView from "./DetailsView";
import EditorView from "./EditorView";
import CreateView from "./CreateView";

interface IProps { }

const Users: React.FC<IProps> = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/users"
			component={TableView}
		/>
		<Route
			path="/dashboard/users/create"
			component={CreateView}
		/>
		<Route
			path="/dashboard/users/details/:id"
			component={DetailsView}
		/>
		<Route
			path="/dashboard/users/edit/:id"
			component={EditorView}
		/>
	</Switch>
);

export default observer(Users);
