import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { makeObservable, observable } from "mobx";
import UIStore from "../../../../stores/UIStore";

export default class Store {
	private router: IRouterPusher;
	private uiStore: UIStore;
	public plan: API.Plan | null = null;

	constructor(id: string,uiStore: UIStore, router: IRouterPusher) {
		this.router = router;
		this.uiStore = uiStore;
		this.getPlan(id);
		makeObservable(this, {
			plan: observable,
		});
	}

	public getPlan = async (id: string) => {
		this.plan = await API.getPlan(id);
	};
}
