import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { makeObservable, observable } from "mobx";
import FileStore from "../../../../stores/FileStore";
import UIStore from "../../../../stores/UIStore";

export default class Store {
	private router: IRouterPusher;
	private uiStore: UIStore;
	public user: API.User | null = null;
	public image: FileStore;

	constructor(id: string,uiStore: UIStore, router: IRouterPusher) {
		this.router = router;
		this.uiStore = uiStore;
		this.getUser(id);
		this.image = new FileStore(this.uiStore, "image");
		makeObservable(this, {
			user: observable,
			image: observable,
		});
	}

	public getUser = async (id: string) => {
		this.user = await API.getUser(id);
		if(this.user.image){
			this.image = new FileStore(this.uiStore, "image", this.user.image);
		}
	};
}
