/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */

import API from "../../modules/API";

import { makeAutoObservable, runInAction } from "mobx";

import UIStore from "../UIStore";
import FormStore from "../FormStore";

import Strings from "../../modules/Strings";
import { Errors } from "../../modules/Errors";

import LoaderShelf from "../../shelves/LoaderShelf";

export default class AuthStore {
	private uiStore: UIStore;

	public adminUser: API.AdminUser | null = null;
	public loader: LoaderShelf;
	public fieldError = {
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
		token: "",
	};
	public receivedToken = "";
	public formController = new FormStore({
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
		token: "",
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		makeAutoObservable(this);
	}

	public isLoginFormReady() {
		const data = this.formController.getValues();
		return data.email.length > 5 && data.password.length >= 6;
	}

	public login = async (onSuccess?: () => void) => {
		const data = this.formController.getValues();
		this.loader.start();
		try {
			const resultAdminUser = await API.adminLogin(
				data.email.trim(),
				data.password.trim(),
			);

			this.adminUser = resultAdminUser;

			localStorage.setItem("adminUser", JSON.stringify(this.adminUser));

			if (onSuccess) {
				onSuccess();
			}
			if ("Notification" in window) {
				if (Notification.permission === "granted") {
					new Notification(Strings.hiAdmin);
				}
			}
			this.clear();
		} catch (e) {
			this.uiStore.showSnackbar(Errors.handleError(e));
		} finally {
			this.loader.end();
		}
	};

	public logout = (onSuccess?: () => void) => {
		this.uiStore.showDialog(Strings.logoutDialog, async () => {
			try {
				await API.logoutAdminUser();
				runInAction(() => (this.adminUser = null));
				localStorage.clear();
				if (onSuccess) {
					onSuccess();
				}
			} catch (e) {
				this.uiStore.showErrorSnackbar(e);
			}
		});
	};

	public validateTokenUrl = async (
		onSuccess: () => void,
		onError: () => void,
		token: string,
	) => {
		this.clearErrors();
		if (this.loader.isLoading) {
			return;
		}

		this.loader.start();

		try {
			const resultValidateUrl = await API.validateToken(token);
			if (resultValidateUrl) {
				runInAction(() => (this.receivedToken = token));
			}

			onSuccess();
		} catch (e) {
			onError();
		} finally {
			this.loader.end();
		}
	};

	public sendNewPassword = async (onSuccess: () => void) => {
		this.clearErrors();
		const data = this.formController.getValues();
		this.loader.start();

		try {
			await API.sendRequestResetPassword(data.email);
			this.uiStore.showSuccessSnackbar();
			onSuccess();
		} catch (e) {
			this.fieldError.email = e.message;
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loader.end();
		}
	};

	public resetPassword = async (onSuccess: () => void) => {
		this.clearErrors();
		const data = this.formController.getValues();

		if (this.loader.isLoading) {
			return;
		}

		this.loader.start();

		try {
			if (data.newPassword !== data.confirmNewPassword) {
				runInAction(() => {
					this.fieldError.password = Strings.recoveryPage.samePasswordError;
					this.fieldError.confirmNewPassword =
						Strings.recoveryPage.samePasswordError;
				});
			} else {
				await API.resetPassword(this.receivedToken, data.newPassword);
				runInAction(() => {
					data.password = data.newPassword;
				});
				this.login();
				this.clear();
				this.uiStore.showSuccessSnackbar();
				onSuccess();
			}
		} catch (e) {
			const error = JSON.parse(e.message);
			this.uiStore.showErrorSnackbar(error.message);
		} finally {
			this.loader.end();
		}
	};

	public validateToken = async (onSuccess: () => void) => {
		this.clearErrors();
		const data = this.formController.getValues();
		if (this.loader.isLoading) {
			return;
		}

		this.loader.start();

		try {
			const resultValidate = await API.validateToken(data.token);

			if (resultValidate) {
				runInAction(() => {
					this.receivedToken = data.token;
				});
			}
			this.uiStore.showSnackbar(Strings.recoveryPage.validToken, "success");
			onSuccess();
		} catch (e) {
			this.fieldError.token = e.message;
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loader.end();
		}
	};

	public isLogged = async () => {
		this.loader.start();

		try {
			const resultAdminUser = await API.getCurrentAdminLogged();
			this.adminUser = resultAdminUser;
		} catch (e) {
			this.uiStore.showSnackbar(Errors.handleError(e), "warning");
			localStorage.clear();
		} finally {
			this.loader.end();
		}

		return !!this.adminUser;
	};

	public authenticate = async (
		onSuccess: () => void = () => {},
		onFail: () => void = () => {},
	) => {
		if (!(await this.isLogged())) {
			onFail();
		} else {
			onSuccess();
		}
	};

	public setToken = (token: string) => {
		runInAction(() => (this.receivedToken = token));
	};

	public clearErrors = () => {
		runInAction(() => {
			this.fieldError.email = "";
			this.fieldError.token = "";
			this.fieldError.password = "";
			this.fieldError.confirmNewPassword = "";
		});
	};

	private clear = () => {
		const data = this.formController.getValues();
		runInAction(() => {
			data.email = "";
			data.password = "";
			this.receivedToken = "";
			data.token = "";
		});
	};
}
