/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
// Mobx
import { makeObservable, observable, runInAction } from "mobx";

// Tools
import FileUploader, { FileType, IImage } from "./FileUploader";

// Modules
import * as Files from "./Files";

// Stores
import UIStore from "../UIStore";

export default class FileStore {
	public progress = 0;
	public loading = false;
	public fileUploaders: FileUploader[] = [];
	public fileType: FileType;

	private uiStore: UIStore;

	constructor(uiStore: UIStore, fileType: FileType, files?: IImage) {
		this.uiStore = uiStore;
		this.fileType = fileType;
		if (files) {
			Array.isArray(files)
				? (this.fileUploaders = files?.map((file) =>
					FileUploader.createUploaded(file),
				))
				: (this.fileUploaders = [
					FileUploader.createUploaded(files),
				]);
		} else {
			this.fileUploaders = [];
		}
		makeObservable(this, {
			fileUploaders: observable,
		});
	}

	public getUncertainfiedMultipleFiles = () => this.fileUploaders.map((uploader) => uploader.getSendableImage());

	public getUncertainfiedImage = () => this.fileUploaders[0]
		? this.fileUploaders[0].getSendableImage()
		: null;

	public getUncertainfiedMultipleImage = () => this.fileUploaders.map((uploader) => uploader.getSendableImage());

	public retryUpload = async (fileId: string) => {
		try {
			for (const uploader of this.fileUploaders) {
				if (uploader.id === fileId) {
					await uploader.uploadImage();
				}
			}
		} catch (error) {
			this.uiStore.showSnackbar(error.message);
		}
	};

	public clear = (fileId: string) => {
		try {
			runInAction(
				() =>
					(this.fileUploaders = this.fileUploaders.filter(
						(uploader) => uploader.id !== fileId,
					)),
			);
		} catch (error) {
			this.uiStore.showSnackbar(error.message);
		}
	};

	public openSelectImageDialog = (
		multiple = false,
		isHighQuality = false,
	) => {
		if (!multiple) {
			Files.openInputUploadDialog(
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				async (file) => {
					runInAction(() => (this.fileUploaders[0] = new FileUploader(file)));
					await this.fileUploaders[0].uploadImage(isHighQuality);
				},
				(error) => {
					throw new Error(error);
				},
				"image/*",
			);
		} else {
			Files.openMultipleInputDialog(
				async (imageFiles) => {
					runInAction(
						() =>
							(this.fileUploaders = this.fileUploaders.concat(
								imageFiles.map((imageFile) => new FileUploader(imageFile)),
							)),
					);

					await Promise.all(
						this.fileUploaders.map(async (uploader) => {
							if (uploader.file) {
								return await uploader.uploadImage();
							}
						}),
					);
				},
				(errMsg) => {
					throw new Error(errMsg);
				},
				"image/*",
			);
		}
	};

	public getUploader = () => this.fileUploaders;

	public toCase = (
		multiple = false,
		isHighQuality = false,
	) => ({
		onAddItem: () => this.openSelectImageDialog(multiple, isHighQuality),
		onRemoveItem: this.clear,
		onReloadItem: this.retryUpload,
		fileUploaders: this.getUploader(),
	});
}
