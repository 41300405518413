import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
	},
	plansNumberText: {
		textAlign: "right",
		alignSelf: "center",
		marginLeft: "auto",
		marginRight: 20,
	},
}));

export default useStyles;
