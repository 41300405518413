import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import Home from "./Home";
import Users from "./Users";
import PlansSettings from "./PlansSettings";

export const Dashboard = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/dashboard" component={Home} />
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/plansSettings" component={PlansSettings} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
