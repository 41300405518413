import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
    latLng: LatLng | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface AdminUser {
    id: string;
    name: string;
    image: Image | null;
    email: string;
    createdAt: Date;
}

export interface PlanDetails {
    appStoreId: string | null;
    playStoreId: string | null;
    airplanesLimit: number;
}

export interface Plan {
    id: string;
    createdAt: Date;
    appStoreId: string | null;
    playStoreId: string | null;
    airplanesLimit: number;
}

export interface NewPlan {
    appStoreId: string | null;
    playStoreId: string | null;
    airplanesLimit: number;
}

export interface EditPlan {
    appStoreId: string | null;
    playStoreId: string | null;
    airplanesLimit: number;
}

export interface UserDetails {
    email: string;
    name: string;
    image: Image | null;
    airplanes: number | null;
}

export interface User {
    id: string;
    plan: Plan | null;
    createdAt: Date;
    email: string;
    name: string;
    image: Image | null;
    airplanes: number | null;
}

export interface NewUser {
    password: string;
    email: string;
    name: string;
    image: Image | null;
    airplanes: number | null;
}

export interface EditUser {
    email: string;
    name: string;
    image: Image | null;
    airplanes: number | null;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    PlanAlreadyRegistered = "PlanAlreadyRegistered",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.PlanAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["PlanAlreadyRegistered"] || ErrorType.PlanAlreadyRegistered : ErrorType.PlanAlreadyRegistered;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.PlanAlreadyRegistered,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.PlanAlreadyRegistered),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getPlan(id: string, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPlan", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        appStoreId: ret.appStoreId === null || ret.appStoreId === undefined ? null : ret.appStoreId,
        playStoreId: ret.playStoreId === null || ret.playStoreId === undefined ? null : ret.playStoreId,
        airplanesLimit: ret.airplanesLimit || 0,
    };
}

export async function getAllPlans(pageOffset: number, progress?: (progress: number) => void): Promise<Plan[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPlans", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        appStoreId: e.appStoreId === null || e.appStoreId === undefined ? null : e.appStoreId,
        playStoreId: e.playStoreId === null || e.playStoreId === undefined ? null : e.playStoreId,
        airplanesLimit: e.airplanesLimit || 0,
    }));
}

export async function getAllPlansWithoutPagination(progress?: (progress: number) => void): Promise<Plan[]> {
    const ret = await makeRequest({name: "getAllPlansWithoutPagination", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        appStoreId: e.appStoreId === null || e.appStoreId === undefined ? null : e.appStoreId,
        playStoreId: e.playStoreId === null || e.playStoreId === undefined ? null : e.playStoreId,
        airplanesLimit: e.airplanesLimit || 0,
    }));
}

export async function getPlansNumber(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getPlansNumber", args: {}, progress});
    return ret || 0;
}

export async function createPlan(newPlan: NewPlan, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        newPlan: {
            appStoreId: newPlan.appStoreId === null || newPlan.appStoreId === undefined ? null : newPlan.appStoreId,
            playStoreId: newPlan.playStoreId === null || newPlan.playStoreId === undefined ? null : newPlan.playStoreId,
            airplanesLimit: newPlan.airplanesLimit || 0,
        },
    };
    const ret = await makeRequest({name: "createPlan", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        appStoreId: ret.appStoreId === null || ret.appStoreId === undefined ? null : ret.appStoreId,
        playStoreId: ret.playStoreId === null || ret.playStoreId === undefined ? null : ret.playStoreId,
        airplanesLimit: ret.airplanesLimit || 0,
    };
}

export async function editPlan(id: string, editPlan: EditPlan, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        id: id,
        editPlan: {
            appStoreId: editPlan.appStoreId === null || editPlan.appStoreId === undefined ? null : editPlan.appStoreId,
            playStoreId: editPlan.playStoreId === null || editPlan.playStoreId === undefined ? null : editPlan.playStoreId,
            airplanesLimit: editPlan.airplanesLimit || 0,
        },
    };
    const ret = await makeRequest({name: "editPlan", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        appStoreId: ret.appStoreId === null || ret.appStoreId === undefined ? null : ret.appStoreId,
        playStoreId: ret.playStoreId === null || ret.playStoreId === undefined ? null : ret.playStoreId,
        airplanesLimit: ret.airplanesLimit || 0,
    };
}

export async function deletePlan(id: string, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePlan", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        appStoreId: ret.appStoreId === null || ret.appStoreId === undefined ? null : ret.appStoreId,
        playStoreId: ret.playStoreId === null || ret.playStoreId === undefined ? null : ret.playStoreId,
        airplanesLimit: ret.airplanesLimit || 0,
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        plan: ret.plan === null || ret.plan === undefined ? null : {
            id: ret.plan.id,
            createdAt: new Date(ret.plan.createdAt + "Z"),
            appStoreId: ret.plan.appStoreId === null || ret.plan.appStoreId === undefined ? null : ret.plan.appStoreId,
            playStoreId: ret.plan.playStoreId === null || ret.plan.playStoreId === undefined ? null : ret.plan.playStoreId,
            airplanesLimit: ret.plan.airplanesLimit || 0,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        airplanes: ret.airplanes === null || ret.airplanes === undefined ? null : ret.airplanes || 0,
    };
}

export async function getAllUsers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        plan: e.plan === null || e.plan === undefined ? null : {
            id: e.plan.id,
            createdAt: new Date(e.plan.createdAt + "Z"),
            appStoreId: e.plan.appStoreId === null || e.plan.appStoreId === undefined ? null : e.plan.appStoreId,
            playStoreId: e.plan.playStoreId === null || e.plan.playStoreId === undefined ? null : e.plan.playStoreId,
            airplanesLimit: e.plan.airplanesLimit || 0,
        },
        createdAt: new Date(e.createdAt + "Z"),
        email: e.email,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        airplanes: e.airplanes === null || e.airplanes === undefined ? null : e.airplanes || 0,
    }));
}

export async function createUser(newUSer: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUSer: {
            password: newUSer.password,
            email: newUSer.email,
            name: newUSer.name,
            image: newUSer.image === null || newUSer.image === undefined ? null : {
                thumb: {
                    width: newUSer.image.thumb.width || 0,
                    height: newUSer.image.thumb.height || 0,
                    url: newUSer.image.thumb.url,
                },
                width: newUSer.image.width || 0,
                height: newUSer.image.height || 0,
                url: newUSer.image.url,
            },
            airplanes: newUSer.airplanes === null || newUSer.airplanes === undefined ? null : newUSer.airplanes || 0,
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        plan: ret.plan === null || ret.plan === undefined ? null : {
            id: ret.plan.id,
            createdAt: new Date(ret.plan.createdAt + "Z"),
            appStoreId: ret.plan.appStoreId === null || ret.plan.appStoreId === undefined ? null : ret.plan.appStoreId,
            playStoreId: ret.plan.playStoreId === null || ret.plan.playStoreId === undefined ? null : ret.plan.playStoreId,
            airplanesLimit: ret.plan.airplanesLimit || 0,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        airplanes: ret.airplanes === null || ret.airplanes === undefined ? null : ret.airplanes || 0,
    };
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            email: editUser.email,
            name: editUser.name,
            image: editUser.image === null || editUser.image === undefined ? null : {
                thumb: {
                    width: editUser.image.thumb.width || 0,
                    height: editUser.image.thumb.height || 0,
                    url: editUser.image.thumb.url,
                },
                width: editUser.image.width || 0,
                height: editUser.image.height || 0,
                url: editUser.image.url,
            },
            airplanes: editUser.airplanes === null || editUser.airplanes === undefined ? null : editUser.airplanes || 0,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        plan: ret.plan === null || ret.plan === undefined ? null : {
            id: ret.plan.id,
            createdAt: new Date(ret.plan.createdAt + "Z"),
            appStoreId: ret.plan.appStoreId === null || ret.plan.appStoreId === undefined ? null : ret.plan.appStoreId,
            playStoreId: ret.plan.playStoreId === null || ret.plan.playStoreId === undefined ? null : ret.plan.playStoreId,
            airplanesLimit: ret.plan.airplanesLimit || 0,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        airplanes: ret.airplanes === null || ret.airplanes === undefined ? null : ret.airplanes || 0,
    };
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        plan: ret.plan === null || ret.plan === undefined ? null : {
            id: ret.plan.id,
            createdAt: new Date(ret.plan.createdAt + "Z"),
            appStoreId: ret.plan.appStoreId === null || ret.plan.appStoreId === undefined ? null : ret.plan.appStoreId,
            playStoreId: ret.plan.playStoreId === null || ret.plan.playStoreId === undefined ? null : ret.plan.playStoreId,
            airplanesLimit: ret.plan.airplanesLimit || 0,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        airplanes: ret.airplanes === null || ret.airplanes === undefined ? null : ret.airplanes || 0,
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
