/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
	Home as HomeIcon,
	Person as UserIcon,
	PhonelinkSetup as PlansSettingsIcon,
} from "@material-ui/icons";
import Strings from "../modules/Strings";

export function useMainRoutes() {
	const commonRoutes = [
		{
			Icon: HomeIcon,
			text: Strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: UserIcon,
			text: Strings.nav.users,
			path: "/dashboard/users",
		},
		{
			Icon: PlansSettingsIcon,
			text: Strings.nav.plansSettings,
			path: "/dashboard/plansSettings",
		},
	];

	return commonRoutes;
}
