import { makeObservable, observable, runInAction } from "mobx";

import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FormStore from "../../../../stores/FormStore";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditPlan extends API.EditPlan {
	id: string;
}

export default class Store extends ModelStore<IEditPlan | API.Plan> {
	protected router: IRouteReplacer;

	public fieldError: FieldErrorShelf<API.PlanDetails>;

	public loader: LoaderShelf;

	public formController = new FormStore({
		appStoreId: "",
		playStoreId: "",
		airplanesLimit: 0,
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);

		this.router = router;
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();

		makeObservable(this, {
			formController: observable,
		});
	}

	protected getModel(id: string) {
		return API.getPlan(id);
	}

	protected afterModelFetch(model: IEditPlan) {
		runInAction(() => {
			this.formController = new FormStore({
				appStoreId: model.appStoreId || "",
				airplanesLimit: model.airplanesLimit,
				playStoreId: model.playStoreId || "",
			});
		});
	}

	protected afterModelFetchError() {
		this.uiStore.showErrorSnackbar(this.error);
		this.router.replace("/dashboard/plansSettings");
	}

	public editPlan = async () => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			const formValues = this.formController.getValues();

			if (!formValues.appStoreId && !formValues.playStoreId) {
				throw new Error(Strings.error.atLeastOneStoreId);
			}

			this.loader.start();
			this.fieldError.clearErrors();

			const plan = await API.editPlan(this.id, {
				appStoreId: formValues.appStoreId || null,
				playStoreId: formValues.playStoreId || null,
				airplanesLimit: Number(formValues.airplanesLimit),
			});

			this.router.replace("/dashboard/plansSettings");
			this.router.go(0);
			this.uiStore.showSnackbar(Strings.plansSettings.edit.success(plan.id));
		} catch (error) {
			this.uiStore.showErrorSnackbar(Errors.handleError(error));
		} finally {
			this.loader.end();
		}
	};
}
