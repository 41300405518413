import React from "react";
import { useHistory } from "react-router";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import { observer, useLocalStore } from "mobx-react-lite";
import CardForm from "../../../../components/CardForm";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import useStyles from "./styles";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));
	const history = useHistory();
	const onSubmitForm = async () => {
		await store.createPlan(() => history.goBack());
	};

	return (
		<CardForm
			title={Strings.plansSettings.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmitForm}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.plansSettings.create.createButton
					)}
				</Button>
			}
		>
			<CardContent className={classes.fields}>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("appStoreId")}
					helperText={store.fieldError.getFieldError("appStoreId")}
					variant="standard"
					type="text"
					label={Strings.fields.appStoreId}
					{...store.formController.field("appStoreId")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("playStoreId")}
					helperText={store.fieldError.getFieldError("playStoreId")}
					variant="standard"
					type="text"
					label={Strings.fields.playStoreId}
					{...store.formController.field("playStoreId")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("airplanesLimit")}
					helperText={store.fieldError.getFieldError("airplanesLimit")}
					variant="standard"
					type="number"
					label={Strings.fields.airplanesLimit}
					{...store.formController.field("airplanesLimit")}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
