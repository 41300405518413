import { makeObservable, observable, runInAction } from "mobx";

import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditUser {
	id: string;
	name: string;
	email: string;
	image: API.Image;
	airplanes: number;
}

export default class Store extends ModelStore<IEditUser | API.User> {
	public userImageService: FileStore | null = null;

	protected router: IRouteReplacer;

	public image: FileStore;

	public fieldError: FieldErrorShelf<API.UserDetails>;

	public loader: LoaderShelf;

	public formController = new FormStore({
		email: "",
		name: "",
		airplanes: 0,
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);

		this.router = router;
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();

		this.router = router;
		this.image = new FileStore(this.uiStore, "image");

		makeObservable(this, {
			userImageService: observable,
			formController: observable,
			image: observable,
		});
	}

	protected getModel(id: string) {
		return API.getUser(id);
	}

	protected afterModelFetch(model: IEditUser) {
		runInAction(() => {
			this.formController = new FormStore({
				name: model.name || "",
				email: model.email || "",
				airplanes: model.airplanes || 0,
			});
			this.image = new FileStore(this.uiStore, "image", model.image);

		});
	}

	protected afterModelFetchError() {
		this.uiStore.showErrorSnackbar(this.error);
		this.router.replace("/dashboard/users");
	}

	public editUser = async () => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			const uncertainImage = this.image.getUncertainfiedImage();

			if (!uncertainImage) {
				return this.uiStore.showErrorSnackbar(
					Strings.error.missingImage,
				);
			}

			const {
				email,
				name,
				airplanes,
			} = this.formController.getValues();

			this.loader.start();
			this.fieldError.clearErrors();

			const user = await API.editUser(this.id, {
				name,
				email,
				airplanes,
				image: uncertainImage.image,
			});

			this.router.replace("/dashboard/users");
			this.router.go(0);
			this.uiStore.showSnackbar(Strings.user.edit.success(user.name));
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
