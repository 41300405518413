import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";
import LinearProgress from "../../../../components/LinearProgress";
import { useGlobalStore } from "../../../context";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<CardForm title={Strings.user.details.title}>
			{!store.user ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.name} value={store.user.name} />
					<DetailRow label={Strings.fields.email} value={store.user.email} />
					{store.image && (
						<FileCase
							onlyRead
							disableActions
							title={Strings.fields.image}
							{...store.image.toCase()}
						/>
					)}
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
