import { makeAutoObservable } from "mobx";

import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import UIStore from "../../../../stores/UIStore";

interface IFormData {
	email: string;
	name: string;
	password: string;
}

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;

	public image: FileStore;

	public fieldError: FieldErrorShelf<IFormData>;

	public avatarController: FileStore | null = null;

	public formController: FormStore<IFormData>;

	constructor(uiStore: UIStore) {
		makeAutoObservable(this);

		this.uiStore = uiStore;

		this.loader = new LoaderShelf();
		this.fieldError = new FieldErrorShelf();
		this.image = new FileStore(this.uiStore, "image");
		this.formController = new FormStore({
			email: "",
			name: "",
			password: "",
		});
	}

	public createUser = async (onSuccessCallback: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();

			const uncertainImage = this.image.getUncertainfiedImage();

			if (!uncertainImage) {
				return this.uiStore.showErrorSnackbar(
					Strings.error.missingImage,
				);
			}

			await API.createUser({
				...this.formController.getValues(),
				image: uncertainImage.image,
				airplanes: null,
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.register.success);
		} catch (error) {
			if (error.type === API.ErrorType.InvalidArgument) {
				this.fieldError.cleanAndAddErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
