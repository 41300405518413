import { createStyles, makeStyles } from "@material-ui/core";

const sidebarOpenWidth = 240;
const sidebarCloseWidth = 57;

export const useStyles = makeStyles(() =>
	createStyles({
		drawerButton: {
			position: "absolute",
			zIndex: 2,
		},
		drawerOpen: {
			width: `${sidebarOpenWidth}px`,
			transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
			transitionProperty: "width",
			transitionDuration: "225ms",
			transitionTimingFunction: "cubic-bezier(0.4, 0, 0.6, 1)",
			transitionDelay: "0ms",
		},
		drawerClose: {
			width: `${sidebarCloseWidth}px`,
			overflowX: "hidden",
			transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
			transitionProperty: "width",
			transitionDuration: "195ms",
			transitionTimingFunction: "cubic-bezier(0.4, 0, 0.6, 1)",
			transitionDelay: "0ms",
		},
	}),
);
