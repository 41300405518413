import { makeAutoObservable } from "mobx";

interface IFieldError<Fields> {
	field: keyof Fields;
	message: string;
}

export default class FieldErrorShelf<Fields> {
	private fieldErrors: Array<IFieldError<Fields>> = [];

	constructor() {
		makeAutoObservable(this);
	}

	public getFieldError(field: keyof Fields): string | null {
		const index = this.fieldErrors.findIndex((error) => error.field === field);

		if (index > -1) {
			return this.fieldErrors[index].message;
		}

		return null;
	}

	public hasFieldError(field: keyof Fields): boolean {
		return this.fieldErrors.findIndex((error) => error.field === field) > -1;
	}

	public addError(error: IFieldError<Fields>) {
		this.fieldErrors = [...this.fieldErrors, error];
	}

	public addErrors(errors: Array<IFieldError<Fields>>) {
		this.fieldErrors = [...this.fieldErrors, ...errors];
	}

	public clearErrors() {
		this.fieldErrors = [];
	}

	public cleanAndAddErrors(errors: Array<IFieldError<Fields>>) {
		this.clearErrors();
		this.addErrors(errors);
	}
}
