import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { IconButton, TableCell, TableRow, Button, Typography } from "@material-ui/core";
import { Edit, Visibility, Delete, Add as AddIcon } from "@material-ui/icons";
import Strings from "../../../../modules/Strings";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import { useGlobalStore } from "../../../context";
import useStyles from "./styles";
import Store from "./store";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new Store(uiStore, history));
	const classes = useStyles();

	const pushToDetails = useCallback(
		(id: string) => history.push(`plansSettings/details/${id}`),
		[history],
	);

	const pushToEdit = useCallback(
		(id: string) => history.push(`plansSettings/edit/${id}`),
		[history],
	);

	const pushToCreate = useCallback(() => history.push("plansSettings/create"), [
		history,
	]);


	const handleDeletePlan = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.deletePlan.message,
					title: Strings.dialogs.deletePlan.title,
				},
				() => tableStore.deletePlan(id),
			);
		},
		[uiStore, tableStore],
	);


	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.plansSettings.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.appStoreId,
					Strings.fields.playStoreId,
					Strings.fields.airplanesLimit,
					Strings.components.table.edit,
					Strings.components.table.details,
					Strings.components.table.delete,
				]}
				renderItem={(item) => (
					<TableRow hover key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.appStoreId}</TableCell>
						<TableCell align={"center"}>{item.playStoreId}</TableCell>
						<TableCell align={"center"}>{item.airplanesLimit}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => handleDeletePlan(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<>
						<Button startIcon={<AddIcon />} onClick={pushToCreate}>
							{Strings.actions.create}
						</Button>
						<Typography className={classes.plansNumberText}>
							{Strings.plansSettings.table.totalAmountPlans(
								tableStore.plansNumber,
							)}
						</Typography>
					</>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
