import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../../modules/Strings";

// MARK: Icons
import { Menu } from "@material-ui/icons";

// MARK: Components
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Detector } from "react-detect-offline";

interface IProps {
	toggleDrawer?: () => void;
}

const Navbar: React.FC<IProps> = (props) => (
	<>
		<AppBar
			elevation={1}
			position="fixed"
			style={{ backgroundColor: "#ffff" }}
		>
			<Toolbar>
				<IconButton
					color="secondary"
					edge="start"
					aria-label="Menu"
					onClick={props.toggleDrawer}
				>
					<Menu color="secondary" />
				</IconButton>
				<Typography color="secondary" variant="h5">
					{Strings.nav.title}
				</Typography>
				<div style={{ flexGrow: 1 }} />
			</Toolbar>
			<Detector
				render={({ online }) =>
					!online && (
						<Alert
							style={{ borderRadius: 0 }}
							variant="filled"
							severity="warning"
						>
							{Strings.nav.disconected}
						</Alert>
					)
				}
			/>
		</AppBar>
	</>
);

export default observer(Navbar);
