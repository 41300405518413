import { makeObservable, observable, runInAction } from "mobx";
import API from "../modules/API";
import { IRouterPusher } from "../interfaces/IRouter";
import UIStore from "../stores/UIStore";
import Strings from "../modules/Strings";

export default abstract class PaginatedListStore<ItemType> {
	private router: IRouterPusher;
	private _uiStore: UIStore;

	public _loading = false;
	public _page = 0;
	public _items: ItemType[] = [];
	public _error: string | null = null;
	public _isHaveNextPage = false;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		this.router = router;
		this._uiStore = uiStore;

		makeObservable(this, {
			_loading: observable,
			_page: observable,
			_items: observable,
			_error: observable,
			_isHaveNextPage: observable,
		});
	}

	public isHaveNextPage = () => this._isHaveNextPage;

	public page = () => this._page;

	protected abstract getDataItemsPerPage(page: number): Promise<ItemType[]>;

	public fetchPage = async (page) => {
		if (page < 0) {
			return;
		}

		if (this._loading) {
			// eslint-disable-next-line no-throw-literal
			throw {
				type: Strings.errorPaginated.type,
				message: Strings.errorPaginated.message,
			};
		}

		runInAction(() => (this._loading = true));

		try {
			const newItems: ItemType[] = await this.getDataItemsPerPage(page);

			if (newItems.length > 0) {
				runInAction(() => {
					runInAction(() => (this._items = newItems));
					this._page = page;
					this._isHaveNextPage = true;
				});
			} else {
				runInAction(() => {
					this._uiStore.showSnackbar(Strings.components.table.noMoreResults);
					this._isHaveNextPage = false;
				});
			}
		} catch (e) {
			if (e.type && e.type === API.ErrorType.NotLoggedIn) {
				this.router.push("/");
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			runInAction(() => (this._error = e.message || "error"));

			throw e;
		} finally {
			runInAction(() => (this._loading = false));
		}
	};

	public refresh = () => {
		this.fetchPage(this.page);
	};

	public clear = () => {
		runInAction(() => (this._items = []));
	};

	public applyFilter = (
		predicate: (item: ItemType, index: number, array: ItemType[]) => void,
	) => {
		runInAction(() => (this._items = this._items.filter(predicate)));
	};

	public nextPage = async () => await this.fetchPage(this._page + 1);

	public previousPage = async () => await this.fetchPage(this._page - 1);

	public getPagination() {
		return {
			page: this.page(),
			onNextClick: () => this.nextPage(),
			onPreviousClick: () => this.previousPage(),
		};
	}

	public setLoading = (isLoading: boolean) => {
		runInAction(() => (this._loading = isLoading));
		return null;
	};
}
