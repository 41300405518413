import api from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import Strings from "../../../../modules/Strings";

export default class Store extends PaginatedListStore<api.User> {
	private authStore: AuthStore;
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(page: number): Promise<api.User[]> {
		return api.getAllUsers(page);
	}

	public async deleteUser(id: string): Promise<void> {
		try {
			await api.deleteUser(id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	}

}
