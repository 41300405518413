import { makeAutoObservable, runInAction } from "mobx";
import React from "react";

export default class FormStore<FormData> {
	private values: FormData;

	constructor(initialValues: FormData) {
		this.values = initialValues;
		makeAutoObservable(this);
	}

	public handleHandle = (field: keyof FormData) => (e: React.ChangeEvent<HTMLInputElement>) => {
		runInAction(
			() =>
				(this.values = {
					...this.values,
					[field]: e.target.value,
				}),
		);
	};

	public field = (field: keyof FormData) => ({
		name: field,
		onChange: this.handleHandle(field),
		value: this.values[field],
	});

	public getValues = () => this.values;
}
