import React from "react";
import { useStyles } from "./styles";

// MARK: Material
import { Box, Card, Link, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface IProps {
	label?: string;
	value?: string | number;
	multiline?: boolean;
	loading?: boolean;
	valueMap?: JSX.Element[];
	link?: boolean;
}

export const DetailRow = (props: IProps) => {
	const classes = useStyles();
	return (
		<>
			<Card elevation={0} className={classes.card}>
				{!props.loading ? (
					<>
						<Box
							className={[
								classes.content,
								classes.singleLine,
								props.multiline && classes.multiline,
							].join(" ")}
						>
							<Typography variant="body2" className={classes.label}>
								{props.label}
							</Typography>
							{!props.valueMap ? (
								props.link ? (
									<Typography variant="h5" className={classes.field}>
										<Link
											variant="body2"
											href={String(props.value)}
											rel="noreferrer"
											target="_blank"
										>
											{props.value}
										</Link>
									</Typography>
								) : (
									<Typography variant="h5" className={classes.field}>
										{props.value}
									</Typography>
								)
							) : (
								props.valueMap
							)}
						</Box>
					</>
				) : (
					<Skeleton />
				)}
			</Card>
		</>
	);
};
