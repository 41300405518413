import React from "react";
import { useStyles } from "./style";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../../modules/Strings";

// MARK: Hooks
import { useMainRoutes } from "../../../hooks/useMainRoutes";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../pages/context";

// MARK: Components
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";

interface IProps {
	open: boolean;
}

const DesktopDrawer: React.FC<IProps> = (props) => {
	const { authStore } = useGlobalStore();
	const classes = useStyles();
	const routes = useMainRoutes();
	const history = useHistory();

	return (
		<Drawer
			variant="permanent"
			className={classes.drawerButton}
			classes={{ paper: props.open ? classes.drawerOpen : classes.drawerClose }}
		>
			<Toolbar />
			<List>
				{routes.map((route) => (
					<ListItem
						key={route.text}
						onClick={() => history.push(route.path)}
						button
					>
						<ListItemIcon>
							<route.Icon/>
						</ListItemIcon>
						{props.open && (
							<ListItemText primary={route.text}/>
						)}
					</ListItem>
				))}
				<ListItem
					onClick={() => authStore.logout(() => history.replace("/login"))}
					button
				>
					<ListItemIcon>
						<ExitToApp />
					</ListItemIcon>
					{props.open && (
						<ListItemText primary={Strings.nav.logout} />
					)}
				</ListItem>
			</List>
		</Drawer>
	);
};

export default observer(DesktopDrawer);
