import { makeAutoObservable } from "mobx";

import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FormStore from "../../../../stores/FormStore";
import UIStore from "../../../../stores/UIStore";

interface IFormData {
	appStoreId: string;
	playStoreId: string;
	airplanesLimit: number;
}

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;

	public fieldError: FieldErrorShelf<IFormData>;

	public formController: FormStore<IFormData>;

	constructor(uiStore: UIStore) {
		makeAutoObservable(this);

		this.uiStore = uiStore;

		this.loader = new LoaderShelf();
		this.fieldError = new FieldErrorShelf();
		this.formController = new FormStore({
			appStoreId: "",
			playStoreId: "",
			airplanesLimit: 0,
		});
	}

	public createPlan = async (onSuccessCallback: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();

			const formValues = this.formController.getValues();

			if (!formValues.appStoreId && !formValues.playStoreId) {
				throw new Error(Strings.error.atLeastOneStoreId);
			}

			await API.createPlan({
				appStoreId: formValues.appStoreId || null,
				playStoreId: formValues.playStoreId || null,
				airplanesLimit: Number(formValues.airplanesLimit),
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.register.success);
		} catch (error) {
			this.uiStore.showErrorSnackbar(Errors.handleError(error));
		} finally {
			this.loader.end();
		}
	};
}
