import LocalizedStrings from "localized-strings";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		enum: {
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
		},
		errorPaginated: {
			type: "StillLoading",
			message: "Ainda estamos buscando os dados, aguarde.",
		},
		fields: {
			id: "id",
			appStoreId: "id App Store (Apple)",
			playStoreId: "id Play Store (Google)",
			airplanesLimit: "Limite de aeronaves",
			city: "Cidade",
			gender: "Gênero",
			complementary: "Complemento",
			neighborhood: "Bairro",
			street: "Rua",
			streetNumber: "Número da rua",
			state: "Estado",
			zipCode: "CEP",
			refuse: "Recusar",
			accepted: "Aceitar",
			archive: "Arquivar",
			document: "Documentação",
			type: "Tipo",
			slug: "slug",
			imageContainer: "Imagem Container",
			image: "Imagem",
			url: "Url",
			tags: "Tags",
			point: "Pontos",
			pointsOrder: "Ordenação por",
			prerequisite: "Pré-requisito",
			images: "Imagens",
			phone: "Telefone",
			price: "Preço",
			whatsapp: "Whatsapp",
			category: "Categoria",
			name: "Nome",
			number: "Número",
			cnpj: "CNPJ",
			userDetails: "Clique para ver mais informações sobre o usuário",
			nick: "Apelido",
			birthdate: "Data de Nascimento",
			cpf: "CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			token: "Token",
			account: "Conta",
			text: "Texto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			description: "Descrição",
			createdAt: "Criado em",
			endAt: "Data de término",
			block: "Data de bloqueio",
			user: "Usuário",
		},
		components: {
			table: {
				noMoreResults: "Sem resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				fallbackData: "Não há dados correspondentes",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				blockAndEditAndDelete: "Editar / Bloquear / Deletar",
				detailsAndblockAndEditAndDelete:
					"Vizualizar / Editar / Bloquear / Deletar",
				detailsAndEditAndDelete: "Editar / Detalhes / Deletar",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},
		filter: {
			all: "Todos",
			createDate: "Data de criação",
			blockeds: "Bloqueados",
			asc: "Menos Pontos",
			desc: "Mais pontos",
			avaliable: "Disponíveis",
		},
		dialogs: {
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message:
					"Tem certeza que deseja apagar o contato " +
					name +
					" ?, essa operação não pode ser revertida.",
			}),
			delete: {
				title: "Confirmação de exclusão.",
				message: "Deseja mesmo excluir este usuário?",
			},
			deletePlan: {
				title: "Confirmação de exclusão.",
				message: "Deseja mesmo excluir este plano? Isso pode afetar a experiência dos seus usuários.",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},
		loading: "Carregando",
		hiAdmin: "Olá, Administrador(a)",
		error: {
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading: "Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingDate: "Insira uma data.",
			invalidDate: "Data inválida.",
			missingType: "Escolha um tipo.",
			aleredySelected: "Já Selecionado!",
			atLeastOneStoreId: "Informe pelo menos um id de plano na loja",
		},
		nav: {
			title: "Airplane",
			home: "Inicio",
			users: "Usuários",
			plansSettings: "Configurações de Planos",
			adminUsers: "Usuários Admin",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			disconected: "Você está descontectado da internet",
		},
		pageLogin: {
			pagetitle: "Airplane",
			title: "Acesso",
			buttonLogin: "Entrar",
			noAccess: "Ainda não possui acesso?",
			requestAccess: "Solicite Aqui",
			recoveryPassword: "Esqueceu a senha?",
			messageFooter: "Desenvolvido de ponta a ponta pela Startapp",
			needHelp: "Precisa de ajuda?",
		},
		home: {
			welcome: "Bem vindo ao dashboard do Airplane",
		},
		user: {
			table: {
				title: "Tabela de usuários",
			},
			details: {
				title: "Detalhes do usuário",
				header: { name: "Nome", email: "Email" },
			},
			edit: {
				title: (userName?: string) => `Edição do Usuário ${userName || ""}`,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (userName: string) => `${userName} modificado com sucesso!`,
			},
			create: {
				title: "Criar usuário",
				createButton: "Criar",
			},
		},
		plansSettings: {
			table: {
				title: "Configurações de planos",
				totalAmountPlans: (amount: number) => `Número de planos cadastrados: ${amount}`,
			},
			details: {
				title: "Detalhes do plano",
				header: { name: "Nome", email: "Email" },
			},
			edit: {
				title: (planId?: string) => `Edição do Plano ${planId || ""}`,
				onClick: "Plano foi editado",
				saveChanges: "Salvar",
				success: (planId: string) => `Plano ${planId} modificado com sucesso!`,
			},
			create: {
				title: "Criar configuração de plano",
				createButton: "Criar",
			},
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		register: {
			success: "Administrador criado com sucesso",
			successPlanSetting: "Configuração de plano criada com sucesso",
			successUser: "Usúario criado com sucesso",
			successLevel: "Nível criado com sucesso",
		},
		recoveryPage: {
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
	},
});

export default Strings;
