import React from "react";
import "./style.scss";

// MARK: Pages
import { Auth } from "./Auth";
import { Login } from "./Login";
import { Dashboard } from "./Dashboard";
import { Recovery } from "./Recovery";

// MARK: Routing
import { Route, Switch, BrowserRouter } from "react-router-dom";

// MARK: GlobalState
import { GlobalContextProvider } from "./context";

// MARK: Components
import { CssBaseline } from "@material-ui/core";
import { BaseLayout } from "../layouts/BaseLayout";
import { observer } from "mobx-react-lite";

const App = () => (
	<GlobalContextProvider>
		<CssBaseline />
		<BaseLayout />
		<BrowserRouter>
			<Switch>
				<Route path="/dashboard">
					<Dashboard />
				</Route>
				<Route exact path="/">
					<Auth />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/recovery/:token">
					<Recovery />
				</Route>
				<Route path="/recovery/">
					<Recovery />
				</Route>
			</Switch>
		</BrowserRouter>
	</GlobalContextProvider>
);

export default observer(App);
