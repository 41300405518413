import React from "react";
import { useHistory, useParams } from "react-router";
import {
	CardContent,
	Button,
	TextField,
} from "@material-ui/core";
import { observer, useLocalStore } from "mobx-react-lite";
import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const { field } = store.formController;
	const classes = useStyles();

	return (
		<PageContainer>
			<CardForm
				title={Strings.plansSettings.edit.title(store.model?.id)}
				loading={store.loader.isLoading}
				actionsFooter={
					<Button
						variant="contained"
						onClick={() => {
							store.editPlan();
						}}
					>
						{Strings.plansSettings.edit.saveChanges}
					</Button>
				}
			>
				<CardContent className={classes.fields}>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("appStoreId")}
						helperText={store.fieldError.getFieldError("appStoreId")}
						variant="standard"
						type="text"
						label={Strings.fields.appStoreId}
						{...field("appStoreId")}
					/>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("playStoreId")}
						helperText={store.fieldError.getFieldError("playStoreId")}
						variant="standard"
						type="text"
						label={Strings.fields.playStoreId}
						{...field("playStoreId")}
					/>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("airplanesLimit")}
						helperText={store.fieldError.getFieldError("airplanesLimit")}
						variant="standard"
						type="number"
						label={Strings.fields.airplanesLimit}
						{...field("airplanesLimit")}
					/>
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};
export default observer(EditorView);
