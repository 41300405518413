import React from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router-dom";
import TableView from "./TableView";
import DetailsView from "./DetailsView";
import EditorView from "./EditorView";
import CreateView from "./CreateView";

interface IProps { }

const PlansSettings: React.FC<IProps> = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/plansSettings"
			component={TableView}
		/>
		<Route
			path="/dashboard/plansSettings/create"
			component={CreateView}
		/>
		<Route
			path="/dashboard/plansSettings/details/:id"
			component={DetailsView}
		/>
		<Route
			path="/dashboard/plansSettings/edit/:id"
			component={EditorView}
		/>
	</Switch>
);

export default observer(PlansSettings);
