import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { useGlobalStore } from "../../../context";
import Store from "./store";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<CardForm title={Strings.plansSettings.details.title}>
			{!store.plan ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.appStoreId} value={store.plan.appStoreId || ""} />
					<DetailRow label={Strings.fields.playStoreId} value={store.plan.playStoreId || ""} />
					<DetailRow label={Strings.fields.airplanesLimit} value={store.plan.airplanesLimit} />
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
