/* eslint-disable prefer-arrow/prefer-arrow-functions */
import React from "react";

// MARK: Stores
import { observer } from "mobx-react-lite";
import { useLocalStore } from "mobx-react-lite";
import AuthStore from "../stores/AuthStore";
import UIStore from "../stores/UIStore";
import { ThemeProvider } from "@material-ui/core";
import useProviderTheme, { ThemeStore } from "../hooks/useProviderTheme";

interface IGlobalStore {
	uiStore: UIStore;
	authStore: AuthStore;
	themeStore: ThemeStore;
}

const globalStoreContext = React.createContext<IGlobalStore | null>(null);

export const GlobalContextProvider = observer((props) => {
	const uiStore = useLocalStore(() => new UIStore());
	const authStore = useLocalStore(() => new AuthStore(uiStore));
	const themeStore = useProviderTheme();

	return (
		<ThemeProvider theme={themeStore.currentTheme}>
			<globalStoreContext.Provider
				value={{
					uiStore,
					authStore,
					themeStore,
				}}
			>
				{props.children}
			</globalStoreContext.Provider>
		</ThemeProvider>
	);
});

export function useGlobalStore() {
	const store = React.useContext(globalStoreContext);
	if (!store) {
		throw new Error("Cannot Access Store outside it's context");
	}

	return store;
}
