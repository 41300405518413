import React from "react";
import { useHistory, useParams } from "react-router";

import {
	CardContent,
	Button,
	TextField,
} from "@material-ui/core";
import { observer, useLocalStore } from "mobx-react-lite";

import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";
import { PageContainer } from "../../../../components/PageContainer";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const formLabels = Strings.fields;
	const { field } = store.formController;
	const classes = useStyles();

	return (
		<PageContainer>
			<CardForm
				title={Strings.user.edit.title(store.model?.name)}
				loading={store.loader.isLoading}
				actionsFooter={
					<Button
						disabled={
							store.userImageService
								? !store.userImageService.getUploader()
								: false
						}
						variant="contained"
						onClick={() => {
							store.editUser();
						}}
					>
						{Strings.user.edit.saveChanges}
					</Button>
				}
			>
				<CardContent className={classes.fields}>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("name")}
						helperText={store.fieldError.getFieldError("name")}
						variant="standard"
						type="text"
						label={Strings.fields.name}
						{...field("name")}
					/>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("email")}
						helperText={store.fieldError.getFieldError("email")}
						variant="standard"
						type="text"
						label={Strings.fields.email}
						{...field("email")}
					/>
					{store.image && (
						<FileCase
							disableActions={store.loader.isLoading}
							title={formLabels.image}
							{...store.image.toCase()}
						/>
					)}
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};
export default observer(EditorView);
